import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AutoComplete from './component/AutoComplete';
import { Card } from '@mui/material';

export default function App() {
  const onScrollFetchProductItem = (
    e: React.UIEvent<HTMLUListElement, UIEvent>
  ) => {
    if (e.currentTarget.scrollTop + 300 >= e.currentTarget.scrollHeight) {
      alert('hello');
    }
  };

  const uniqueProductData = [
    { id: '1', name: 'name-1', code: 'code-1' },
    { id: '2', name: 'name-2', code: 'code-2' },
    { id: '3', name: 'name-3', code: 'code-3' },
    { id: '4', name: 'name-4', code: 'code-4' },
    { id: '5', name: 'name-5', code: 'code-5' },
    { id: '6', name: 'name-6', code: 'code-6' },
    { id: '7', name: 'name-7', code: 'code-7' },
    { id: '8', name: 'name-8', code: 'code-8' },
    { id: '9', name: 'name-9', code: 'code-9' },
    { id: '10', name: 'name-10', code: 'code-10' },
  ];

  return (
    <Container maxWidth='sm'>
      <Box sx={{ my: 4 }}>
        <Card sx={{ p: 3 }}>
          <AutoComplete
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
              } else if (newValue) {
              }
            }}
            onInputChange={(event, newInputValue) => {
              if (!newInputValue) {
              }
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            getOptionLabel={(option) => option.name}
            fetchFunction={onScrollFetchProductItem}
            label='Scroll Test'
            loading={false}
            options={uniqueProductData}
            value={
              uniqueProductData?.filter(
                (productItem) => productItem.id === '1'
              )?.[0] || null
            }
          />
        </Card>
      </Box>
    </Container>
  );
}
