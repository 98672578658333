import * as React from 'react';
import { ReactNode } from 'react';
import Loader from './Loader';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface IProps<T> {
  label: string;
  loading: boolean;
  value: T;
  options: T[];
  helperText?: string;
  error?: boolean;
  searchFunction?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fetchFunction?: (e: React.UIEvent<HTMLUListElement, UIEvent>) => void;
  getOptionLabel: (option: T) => string;
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T
  ) => ReactNode;
  onChange?: (event: React.SyntheticEvent<Element, Event>, newValue: T) => void;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => void;
  disabled?: boolean;
  // filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
}

const AutoComplete = <T extends object>(
  props: IProps<T> & { children?: ReactNode }
) => {
  const {
    label,
    loading,
    value,
    error,
    helperText,
    fetchFunction,
    options,
    searchFunction,
    onChange,
    onInputChange,
    renderOption,
    getOptionLabel,
    disabled,
  } = props;

  return (
    <Autocomplete
      loading={loading}
      loadingText={<Loader />}
      value={value}
      onInputChange={onInputChange}
      onChange={onChange}
      id='controllable-states-demo'
      disableClearable
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={label}
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
        />
      )}
      ListboxProps={{
        onScroll: fetchFunction,
        role: 'list-box',
        style: {
          maxHeight: 300,
        },
      }}
      disabled={disabled}
    />
  );
};

export default AutoComplete;
